import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ProductLine } from '@kouto/types';
import { getProductCardLabel } from 'utils/listings';
import { useImagePreloader } from 'hooks/use-image-preloader';
import useCustomHistory from 'hooks/use-custom-history';
import { SkeletonLine } from 'components/theme/Skeleton/Skeleton';
import useProductList from './useProductList';

const ZOOM_IN_DURATION = 3000; // ms

interface Props {
  title: string;
  smallTitle?: boolean;
}

export const ProductsList: FC<Props> = ({ title, smallTitle = false }) => {
  const history = useCustomHistory();
  const { t: translate } = useTranslation();
  const { products, isLoading: isLoadingProductList } = useProductList();
  const { success, error } = useImagePreloader(
    Object.values(products)
      .map((p) => p.bg)
      .filter((bg) => !!bg),
  );

  const goToProductLine = useCallback(
    (productLine: ProductLine) => {
      history.push({ pathname: `/landing/${productLine}` });
    },
    [history],
  );

  if (
    !isLoadingProductList &&
    Object.values(products).filter((bg) => !!bg).length === 0
  ) {
    return null;
  }

  if (error) {
    return null;
  }

  if (isLoadingProductList) {
    return (
      <Wrapper className="landing-page-product-list-container">
        {!smallTitle && <Title>{title}</Title>}
        {smallTitle && <SmallTitle>{title}</SmallTitle>}
        <CardScroller>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </CardScroller>
      </Wrapper>
    );
  }

  const renderProductCard = (
    productLine: ProductLine,
    product: { bg: string; count: number },
  ) => (
    <Card
      key={productLine}
      onClick={() => goToProductLine(productLine)}
      className={success ? 'product-list-card' : 'loading product-list-card'}
      tabIndex={0}
    >
      {success && (
        <CardImg
          src={product.bg}
          alt={`Image for ${getProductCardLabel(productLine, translate)}`}
          className="product-list-card-image"
        />
      )}
      <CardFooter className="product-list-card-footer">
        <CardTitle className="product-list-card-title">
          {getProductCardLabel(productLine, translate)}
        </CardTitle>
        <CardSubTitle className="product-list-card-subtitle">
          {product.count}{' '}
          {translate(product.count === 1 ? 'experience' : 'experiences')}
        </CardSubTitle>
      </CardFooter>
    </Card>
  );

  return (
    <Wrapper className="landing-page-product-list-container">
      {!smallTitle && <Title>{title}</Title>}
      {smallTitle && <SmallTitle>{title}</SmallTitle>}
      <CardScroller>
        {Object.entries(products).map(([productLine, product]) =>
          renderProductCard(productLine as ProductLine, product),
        )}
      </CardScroller>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 48px 0 120px;
  width: 100%;
  overflow: hidden;
`;

const Title = styled.h2`
  margin: 0 0 48px 0;
  font-size: 32px;
  line-height: 38px;
  color: var(--way-colors-primaryTextColor);
`;

const SmallTitle = styled.h3`
  font-size: 18px;
  line-height: 24px;
  color: var(--way-colors-primaryTextColor);
  margin-bottom: 24px;
  font-weight: 100;
`;

const CardScroller = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 24px;
  overflow-x: auto;
`;

const CardTitle = styled.h3`
  font-size: 22px;
  line-height: 110%;
  font-weight: 800;
  color: var(--way-palette-white-100);
  margin: 0px;
`;

const CardSubTitle = styled.h4`
  font-size: 14px;
  line-height: 150%;
  font-weight: 100;
  color: var(--way-palette-white-80);
  margin: 0px;
`;

const CardImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  scale: 1;
  animation-iteration-count: 1;
  transition: scale ${ZOOM_IN_DURATION / 1000}s cubic-bezier(0, 0.7, 0.28, 1);
  z-index: 10;
`;

const cardResponsiveSizeCss = `
  aspect-ratio: 0.8;

  @media (max-width: 768px) {
    max-width: 75vw;
    min-width: 75vw;
  }

  @media (min-width: 769px) {
    max-width: 320px;
    min-width: 320px;
  }

  @media (min-width: 992px) {
    max-width: calc((100% - 48px) / 3);
    min-width: calc((100% - 48px) / 3);
  }

  @media (min-width: 1378px) {
    max-width: 410px;
    min-width: 410px;
  }
`;

const Card = styled.div`
  position: relative;
  cursor: pointer;
  opacity: 1;
  overflow: hidden;

  & > img.product-list-card-image {
    height: 100%;
  }

  &:hover img.product-list-card-image {
    scale: 1.1;
  }

  ::before {
    content: ' ';
    background: linear-gradient(-90deg, #c1c1c1 0%, #f8f8f8 50%, #c1c1c1 100%);
    background-size: 400% 400%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.4s 0.1s ease-in-out;
  }

  &.loading::before {
    opacity: 1;
    animation: pulse 1.2s ease-in-out infinite;
    @keyframes pulse {
      0% {
        background-position: 0% 0%;
      }
      100% {
        background-position: -135% 0%;
      }
    }
  }

  ${cardResponsiveSizeCss}
`;

const CardFooter = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  left: 0px;
  gap: 2px;
  bottom: 0px;
  z-index: 20;
  background: linear-gradient(
    0deg,
    var(--way-palette-black-70) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  @media (max-width: 768px) {
    padding: 14px;
    height: 68px;
  }
`;

const CardSkeleton = styled(SkeletonLine)`
  position: relative;
  border-radius: var(--way-design-borderRadiusDefault);

  ${cardResponsiveSizeCss}
`;
